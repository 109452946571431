import { Controller } from '@hotwired/stimulus';

const activeClasses = {
  'true': ['border-teal-500', 'text-teal-600'],
  'false': ['border-transparent', 'text-gray-500', 'hover:border-gray-300', 'hover:text-gray-700']
};

// Connects to data-controller="tab"
export default class extends Controller {
  static targets = ['link', 'option', 'select', 'tab'];

  onChange(e) {
    e.preventDefault();

    const selectedOption = e.target.options[e.target.selectedIndex];

    Array.from(this.tabTargets).forEach((tab) => {
      if (tab.dataset.id === selectedOption.dataset.id) {
        tab.classList.remove('hidden');
      } else {
        tab.classList.add('hidden');
      }
    });

    Array.from(this.linkTargets).forEach((link, index) => {
      if (link.dataset.id === selectedOption.dataset.id) {
        link.classList.remove(...activeClasses['false']);
        link.classList.add(...activeClasses['true']);
      } else {
        link.classList.remove(...activeClasses['true']);
        link.classList.add(...activeClasses['false']);
      }
    });
  }

  onClick(e) {
    e.preventDefault();

    Array.from(this.tabTargets).forEach((tab) => {
      if (tab.dataset.id === e.target.dataset.id) {
        tab.classList.remove('hidden');
      } else {
        tab.classList.add('hidden');
      }
    });

    Array.from(this.optionTargets).forEach((option, index) => {
      if (option.dataset.id === e.target.dataset.id) {
        this.selectTarget.options[index].selected = true
      }
    });

    Array.from(this.linkTargets).forEach((link, index) => {
      if (link.dataset.id === e.target.dataset.id) {
        link.classList.remove(...activeClasses['false']);
        link.classList.add(...activeClasses['true']);
      } else {
        link.classList.remove(...activeClasses['true']);
        link.classList.add(...activeClasses['false']);
      }
    });
  }
}
